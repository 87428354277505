import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/_services/auth.service';
import { Helpers } from '@app/helpers/helpers';
import { ResponseBodyFull } from '@app/models';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL;
  getReport(whatUrl, filters: any = null) {
    this.BASE_URL = `${environment.apiUrl}/${whatUrl}`;
    this.ifIncludeStatus(whatUrl, filters);
    this.ifIncludeTimeEstimates(whatUrl, filters);
    let params = new HttpParams();
    if (filters) {
      params = Helpers.populateFilters(params, filters, true);
    }
    // TODO моделі для всіх звітів
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<any>>(`${this.BASE_URL}`, { params }).pipe(map(resp => {
        if (resp['hydra:member']) {
          return resp['hydra:member']
        }
        return resp;
      }));
    } else {
      return new Observable<any>();
    }
  }
  ifIncludeStatus(whatUrl, filters) {
    this.BASE_URL = `${environment.apiUrl}/${whatUrl}`;
    if (filters && filters.includePendingMatters && filters.includeClosedMatters) {
      this.BASE_URL = `${environment.apiUrl}/matters?itemsPerPage=10000&status[]=open&status[]=pending&status[]=closed`;
    } else if (filters && filters.includePendingMatters) {
      this.BASE_URL = `${environment.apiUrl}/matters?itemsPerPage=10000&status[]=open&status[]=pending`;
    } else if (filters && filters.includeClosedMatters) {
      this.BASE_URL = `${environment.apiUrl}/matters?itemsPerPage=10000&status[]=open&status[]=closed`;
    }
    filters.includePendingMatters = null;
    filters.includeClosedMatters = null;
  }
  ifIncludeTimeEstimates(whatUrl, filters) {
    this.BASE_URL = `${environment.apiUrl}/${whatUrl}`;
    // p[]=timeEstimateWait&p[]=timeEstimateCanceled&p[]=timeEstimateDone&p[]=timeEstimateTotal
    if (filters && filters.checkTaskProgressByUser) {
      this.BASE_URL = `${environment.apiUrl}/users/report?itemsPerPage=10000&
      p[]=timeActualInReview&
      p[]=countTaskInReview&
      p[]=timeEstimateInReview&

      p[]=countTaskNew&p[]=user&p[]=countTaskInWork&p[]=countTaskCanceled&p[]=timeActualNew&p[]=timeActualInWork&p[]=timeActualCanceled&p[]=countTaskTotal&p[]=timeActualTotal&p[]=countTaskDone&p[]=timeActualDone&p[]=timeEstimateNew&p[]=timeEstimateInWork&p[]=timeEstimateWait&p[]=timeEstimateCanceled&p[]=timeEstimateDone&p[]=timeEstimateTotal`;
    }
    filters.checkTaskProgressByUser = null;
  }
}
